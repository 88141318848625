body{
  background: linear-gradient(to right,#cb11ab 0,#481173 100%) no-repeat;
  overflow-x: hidden;
  .menu{
    background: linear-gradient(to right,#cb11ab 0,#481173 100%) no-repeat;
    padding: 15px 10px;
    .container-fluid{
      .top{
        display: flex;
        align-items: center;
        justify-content:space-between;
        .country{
          display: flex;
          align-items: center;
          justify-content: start;
          cursor: pointer;
          h6{
            margin-bottom: 0;
            font-size: 16px;
            color: rgba(255,255,255,.6);
            font-weight: 400;
            &:hover{
              color: #fff;
            }
          }
          img{
            width: 15px;
            height: 15px;
            border-radius: 50%;
            margin-left: 7px;
          }
        }
        .capital{
          cursor: pointer;
          display: flex;
          margin-left: 40px;
          h6{
            margin-bottom: 0;
            margin-left: 7px;
            font-size: 16px;
            color: rgba(255,255,255,.6);
            font-weight: 400;
            &:hover{
              color: #fff;
            }
          }
          i{
            color: rgba(255,255,255,.6);
            &:hover{
              color: #fff;
            }
          }
        }
        a{
          text-decoration: none;
          background-color: transparent;
          cursor: pointer;
          font-size: 16px;
          line-height: 20px;
          color: rgba(255,255,255,.6);
          border-color: initial;
          display: inline-block;
          min-height: 20px;
          margin-left: 50px;
          &:hover{
            color: #fff;
          }
        }
        .top-first-button{
          color: #fff;
          padding: 3px 12px;
          background: #cb11ab linear-gradient(
                          90deg,#bf5ae0 0,#a811da 100%);
          border-radius: 40px;
          border:#cb11ab ;
          font-size: 14px;
          line-height: 20px;
          margin-left: 40px;
        }
        .top-second-button{
          position: relative;
          padding: 4px 26px 4px 8px;
          background: #cb11ab;
          border-radius: 28px;
          -ms-flex-negative: 0;
          flex-shrink: 0;
          border: #cb11ab;
          p{
            font-size: 14px;
            display: inline-block;
          }
        }
      }
      .menu-bar{
        margin-top: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        img{
          object-fit: contain;
        }
       a{
         text-decoration: none;
         .menu-bar-info{
           display: flex;
           flex-direction: column;
           align-items: center;
           margin-right: 40px;
           &:nth-child(3){
             margin-right: 0;
           }
         }
       }
        .menu-bars{
          border: 1px solid rgba(255,255,255,.4);
          border-radius: 8px;
          padding: 10px;
          margin-right: 20px;
          i{
            font-size: 35px;
            color: #ffffff;
          }
        }
        .none-menu{
          transition: all 0.3s linear;
          width: 400px;
          height: 100%;
          background-color: #ffffff;
          position: fixed;
          top: 0;
          left: 0;
          z-index: 10;
          overflow-y: scroll;
          ul{
            position: relative;
            padding: 0;
            li{
              list-style: none;
              margin-top: 10px;
              padding-left: 0;
              a{
                font-size: 18px;
                line-height: 22px;
                color: #000;
                display: block;
                position: relative;
                padding: 3px 5px 3px 44px;
                border-radius: 8px;
                -ms-text-size-adjust: 100%;
                -moz-text-size-adjust: 100%;
                -webkit-text-size-adjust: 100%;
                padding-left: 10px;
                margin-left: 30px;
                width: 70%;
                &:hover{
                  background-color: #de00b7;
                  color: #ffffff;
                }
                i{
                  font-size: 25px;
                  margin-right: 10px;
                }
              }
            }
          }

        }

        .btn-warningg{
          position: fixed;
          top: 2%;
          left: 27%;
          cursor: pointer;
          transition: all 0.2s linear;
          z-index: 10;
          i{
            font-size: 40px;
            //color: #ffffff;
            color: black;
          }
        }
        .input-group{
          margin-left: 40px;
          font-size: 18px;
          line-height: 24px;
          -webkit-transition: background .3s ease 0s;
          transition: background .3s ease 0s;
          cursor: pointer;
          input{
            cursor: pointer;
            background-color: rgba(255,255,255,.2);
            height: 100%;
            width: 600px!important;
            padding: 16px 10px 16px 20px;
            border-radius: 55px;
            color: white;
            border:rgba(255,255,255,.2);
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            &::placeholder{
              color: rgba(255,255,255,.5);
            }
            &:hover{
              background-color: rgba(255,255,255,.5);
            }
          }
          .input-group-append{
            button{
              background-color: rgba(255,255,255,.2);
              border: 1px solid rgba(255,255,255,0);
              padding: 14px;
              &:nth-child(2){
                border-top-right-radius: 55px;
                border-bottom-right-radius: 55px;
              }
              i{
                color: rgba(255,255,255,.5);
                &:hover{
                  color: white;
                }
              }
            }
          }
        }
        .menu-end-div{
          i{
            height: 27px;
            color: #ffffff;
            font-size: 23px;
          }
          h6{
            color: rgba(255,255,255,.6);
            font-weight: 400;
            font-size: 14px;
            &:hover{
              color: #ffffff;
            }
          }
        }
      }
      .d-nonee{
        display: none;
        .input-group{

          font-size: 18px;
          line-height: 24px;
          -webkit-transition: background .3s ease 0s;
          transition: background .3s ease 0s;
          cursor: pointer;
          input{
            cursor: pointer;
            background-color: rgba(255,255,255,.2);
            height: 100%;
            //width: 100%!important;
            padding: 16px 10px 16px 20px;
            border-radius: 55px;
            color: white;
            border:rgba(255,255,255,.2);
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            &::placeholder{
              color: rgba(255,255,255,.5);
            }
            &:hover{
              background-color: rgba(255,255,255,.5);
            }
          }
          .input-group-append{
            button{
              background-color: rgba(255,255,255,.2);
              border: 1px solid rgba(255,255,255,0);
              padding: 14px;
              &:nth-child(2){
                border-top-right-radius: 55px;
                border-bottom-right-radius: 55px;
              }
              i{
                color: rgba(255,255,255,.5);
                &:hover{
                  color: white;
                }
              }
            }
          }
        }
      }
    }
  }
  .header{
    background-color: #ffffff;
    padding: 30px 20px;
    border-radius: 30px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    .react-multi-carousel-list{
      .react-multiple-carousel__arrow--left{
        z-index: 1;
      }
      .react-multiple-carousel__arrow--right{
        z-index: 1;
      }
    }
    figure{
      &:hover .carousel-img{
        opacity: 1;
        -webkit-animation: flash 1.5s;
        animation: flash 1.5s;
      }
      @-webkit-keyframes flash {
        0% {
          opacity: .4;
        }
        100% {
          opacity: 1;
        }
      }
      @keyframes flash {
        0% {
          opacity: .4;
        }
        100% {
          opacity: 1;
        }
      }
    }
    .first-cards{
      margin-top: 40px;
      .col-3{
        margin-top: 30px;
        .card{
          border: none;
          border-radius: 20px;
          .card-body{
            overflow: hidden;
            border-radius: 20px;
            figure{
              &:hover img{
                transform: scale(1.1);
                border-radius: 20px;
              }
              img{
                border-radius: 20px;
                transform: scale(1);
                transition: all 0.5s linear;
              }
            }
          }
        }
      }
    }
    .second-cards{
      margin-top: 70px;
      h1{
        margin-bottom: 20px;
        font-size: 36px;
        line-height: 48px;
        font-weight: 700;
      }
      .col-2{
        margin-top: 15px;
        img{
          width: 100%;
        }
        &:hover button{
          opacity: 1;
        }
        a{
          text-decoration: none;
          color: #212121;
          position: relative;
          .card{
            .card-body{
              img{
                border-radius: 10px;
              }
              h6{
                margin-bottom: 0;
                font-size: 18px;
                line-height: 24px;
              }
              span{
                margin-left: 10px;
                font-size: 14px;
                line-height: 20px;
                color: #999;
                text-decoration: line-through;
                max-width: 100%;
              }
              p{
                overflow: hidden;
                position: relative;
                z-index: 1;
                display: -webkit-box;
                -webkit-line-clamp: 1!important;
                -webkit-box-orient: vertical;
                color: #696969;
                font-size: 14px;
                line-height: 20px;
                font-weight: 400;
                height: 25px;
                cursor: pointer;
              }
            }
          }
        }
        button{
          opacity: 0;
          font-size: 12px;
          line-height: 16px;
          -webkit-transition: all .3s ease 0s;
          transition: all .3s ease 0s;
          border-radius: 50px;
          background-color: rgba(200,200,200, 1);
          cursor: pointer;
          padding: 10px 11px;
          border: none;
          position: absolute;
          top: 50%;
          left: 50%;
          -webkit-transform: translate(-50%,-50%);
          transform: translate(-60%,-70%);
          white-space: nowrap;
          z-index: 3;
        }
      }
    }
    .three-cards{
      margin-top: 40px;
      margin-bottom: 30px;
      display: flex;
      align-items: center;
      a{
        flex:0 0 50%;
        max-width: 50%;
        display: inline-block;
        .col-6{
          flex:0 0 100%;
          max-width: 100%;
          .card{
            border: none;
            .card-body{
              padding: 0;
              overflow: hidden;
              border-radius: 20px;
              figure{
                position: relative;
                &::before{
                  position: absolute;
                  top: 0;
                  left: -75%;
                  z-index: 2;
                  display: block;
                  content: '';
                  width: 50%;
                  height: 100%;
                  background: -webkit-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,.3) 100%);
                  background: linear-gradient(to right, rgba(255,255,255,0) 0%, rgba(255,255,255,.3) 100%);
                  -webkit-transform: skewX(-25deg);
                  transform: skewX(-25deg);
                }
                &:hover::before{
                  -webkit-animation: shine .75s;
                  animation: shine .75s;
                }
                @-webkit-keyframes shine {
                  100% {
                    left: 125%;
                  }
                }
                @keyframes shine {
                  100% {
                    left: 125%;
                  }
                }

                img{
                  border-radius: 20px;
                  width: 100%;
                  //transition: all 0.5s linear;
                }
              }
            }
          }
        }
      }
    }
    .cards-button{
      margin: 30px 0;
      .col-12{
        display: flex;
        align-items: center;
        justify-content: center;
        button{
          background-color: #fff;
          border: 2px solid #cb11ab;
          color: #cb11ab;
          border-radius: 26px;
          padding: 10px 30px;
          font-weight: bold;
          i{
            margin-left: 5px;
            vertical-align: middle;
          }
          &:hover{
            background-color: #cb11ab;
            color: #ffffff;
          }
        }
      }
    }
  }
  .brand-main{
    background-color: #ffffff;
    position: relative;
    padding-left: 30px;
    padding-right: 30px;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
    .top-brand{
      h1{
        font-size: 36px;
        line-height: 48px;
      }
      a{
        font-size: 18px;
        line-height: 22px;
        color: #cb11ab!important;
        text-decoration: none;
        margin-top: 10px;
        margin-left: 20px;
        font-weight: 600;
      }
    }
    .react-multi-carousel-list{
      position: static;
      .react-multiple-carousel__arrow--left{
        top: 9%;
        left: 10px;
        z-index: 1;
        background-color: #ffffff;
        box-shadow: 0 0 10px rgba(0,0,0,0.9);
        &:before{
          color: black;
          font-weight: 900!important;
          font-size: 25px!important;
        }
      }
      .react-multiple-carousel__arrow--right{
        top: 9%;
        right: 10px;
        z-index: 1;
        background-color: #ffffff;
        box-shadow: 0 0 10px rgba(0,0,0,0.9);
        &:before{
          color: black;
          font-weight: 900!important;
          font-size: 25px!important;
        }
      }
    }
    .col-2a{
      text-align: center;
      button{
        margin-top: 20px;
        margin-bottom: 20px;
        text-overflow: ellipsis;
        color: #000;
        padding: 12px 10px 14px;
        border: 1px solid #d5d5d5;
        border-radius: 60px;
        -webkit-transition: all .3s linear 0s;
        transition: all .3s linear 0s;
        width: 80%;
        background-color: #ffffff;
        a{
          text-decoration: none;
          color: black;
          overflow: hidden;
          position: relative;
          z-index: 1;
          display: -webkit-box;
          -webkit-line-clamp: 1!important;
          -webkit-box-orient: vertical;
          font-size: 16px;
          //line-height: 20px;
          font-weight: 400;
          height: 25px;
          cursor: pointer;
          margin-bottom: 0;
        }
        &:hover{
          border: 1px solid #cb11ab;
          color: #cb11ab!important;
        }
      }
      ul{
        padding: 0;
        li{
          list-style: none;
          padding: 0;
          a{
            img{
              margin-top: 20px;
            }
          }
        }
      }
    }
    .row{
      padding-left: 30px;
      padding-right: 30px;
      margin-top: 60px;
      padding-bottom: 70px;
      h6{
        font-size: 20px;
        line-height: 28px;
        margin-bottom: 20px;
        font-weight: 700;
      }
      p{
        text-decoration: none;
        color: #8b8b8b;
        overflow: hidden;
        position: relative;
        z-index: 1;
        display: -webkit-box;
        -webkit-line-clamp: 3!important;
        -webkit-box-orient: vertical;
        font-size: 16px;
        //line-height: 20px;
        font-weight: 400;
        height: 70px;
        cursor: pointer;
        margin-bottom: 0;
      }
      a{
        border-bottom: 1px dashed;
        font-size: 16px;
        line-height: 22px;
        color: #000;
        border-color: initial;
        text-decoration: none;
        background-color: transparent;
        cursor: pointer;
        color: #cb11ab;
        border-color: rgba(203,17,171,.2);
        font-weight: 600;
        display: inline-block;
        margin-top: 12px;
      }
    }
  }
  .footer{
    padding: 40px;
    .footer-main{
      display: flex;
      justify-content: space-between;
      .footer-items{
        img{
          width: 150px;
          height: 48px;
        }
        h6{
          overflow-wrap: break-word;
          word-break: break-word;
          font-size: 18px;
          line-height: 24px;
          color: #fff;
          font-weight: 600;
        }
        ul{
          padding: 0;
          li{
            list-style: none;
            a{
              display: block;
              color: #ffffff;
              word-wrap: break-word;
              overflow-wrap: break-word;
              word-break: break-word;
              font-size: 16px;
              line-height: 22px;
              color: rgba(255,255,255,.7);
              text-decoration: none;
              vertical-align: middle;
              padding: 8px 0;
              &:hover{
                color: white;
              }
            }
          }
        }
      }
    }
    .footer-end{
      text-align: center;
      p{
        font-size: 16px;
        line-height: 20px;
        color: rgba(255,255,255,.7);
      }
      a{
        text-decoration: none;
        background-color: transparent;
        cursor: pointer;
        font-size: 16px;
        line-height: 20px;
        color: rgba(255,255,255,.7);
        //border-color: rgba(255,255,255,.7);
        border-bottom: 1px solid;
        border-bottom-color: rgba(255, 255, 255, 0.7);
      }
      div{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 30px;
        i{
          font-size: 44px;
          margin-left: 10px;
          color: rgba(255, 255, 255, 0.7);
          &:first-child{
            margin-left: 0;
          }
        }
      }
    }
  }
  ::-webkit-scrollbar {
    width: 10px;
    height: 10px!important;
  }
  ::-webkit-scrollbar-track {
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    background: #e8e8e8;
    border-radius: 10px;
    height: 10px!important;
  }
  @media (max-width: 1400px) {
    .menu{
      .container-fluid{
        .top{
          .top-second-button{
           padding: 10px;
            p{
              display: none;
            }
          }
        }
        .menu-bar{
          .input-group{
            display: none;
          }
        }
        .d-nonee{
          margin-top: 30px;
          display: block;
        }
      }
    }
  }
  @media (max-width: 1000px) {
    .footer{
      .footer-main{
        .footer-items{
          display: none;
        }
        display: flex;
        flex-direction: column;
        .dropdown{
          width: 100%;
          display: block;
          margin-top: 10px;
          img{
            width: 150px;
            height: 48px;
          }
          button{
            width: 100%;
          }
          .dropdown-menu{
            width: 100%;
            a{
              color: black;
            }
          }
        }
      }
      .footer-end{
        margin-top: 70px;
        p{
          font-size: 13px;
        }
      }
    }
  }
  @media (max-width: 1200px) {
    .second-cards{
      .col-2{
        flex:0 0 25%;
        max-width: 25%;
      }
    }
  }
  @media (max-width: 768px) {
    .second-cards{
      .col-2{
        flex:0 0 33.33%;
        max-width: 33.3%;
      }
    }
    .three-cards{
      a{
        flex: 0 0 100% !important;
        max-width: 100% !important;
        img{
          object-fit: contain;
        }
      }
    }
    .first-cards{
      .col-3{
        flex: 0 0 50%;
        max-width: 50%;
      }
    }
    .header{
      padding-left: 0;
      padding-right: 0;
      .react-multi-carousel-list{
        .react-multiple-carousel__arrow--left{
          z-index: 1;
          display: none;
        }
        .react-multiple-carousel__arrow--right{
          z-index: 1;
          display: none;
        }
      }
    }
    .brand-main{
      .react-multi-carousel-list{
        .react-multiple-carousel__arrow--left{
          z-index: 1;
          display: none;
        }
        .react-multiple-carousel__arrow--right{
          z-index: 1;
          display: none;
        }
      }
    }
  }
  @media (max-width: 678px) {
    .menu{
      .container-fluid{
        .top{
          display: none;
        }
        .menu-bar{
          margin-top: 0;
          img{
           width: 150px;
            height: 50px;
          }
          .menu-bars{
            padding: 5px;
            margin-right: 5px;
            i{
              font-size: 20px;
            }
          }
          .menu-end-div{
            .menu-bar-info{
              margin-right: 5px;
              i{
                font-size: 15px;
                margin-top: 10px;
              }
              .fa-map-marker-alt{
                display: none;
              }
              h6{
                display: none;
              }
            }
          }
        }
        .d-nonee{
          margin-top: 0!important;
        }
      }
    }
    .header{
      .first-cards{
        margin-top: 0px!important;
      }
    }
    .brand-main{
      .top-brand{
        text-align: center;
        display: block!important;
        h1{
          font-size: 20px;
          line-height: 30px;
        }
        a{
          margin-left: 0;
        }
      }
      .col-2a{
        img{
          width: 100% !important;
        }

      }
      h6{
        font-size: 16px!important;
        line-height: 22px!important;
      }
    }
    .second-cards{
      .col-2{
        h6, span{
          display: none;
        }
      }
    }

  }
  @media (max-width: 600px) {
    .second-cards{
      .col-2{
        flex:0 0 50%;
        max-width: 50%;
      }
    }
  }
  @media (max-width: 400px) {
    .menu{
      .container-fluid{
        .menu-bar{
          .btn-warningg{
            left: 80%;
          }
        }
      }
    }
  }
}